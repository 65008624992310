<template>
  <form-field :name="field.name">
    <v-radio-group
      v-model="field.value"
      :rules="field.props.rules"
      required
    >
      <v-radio
        label="Male"
        ripple
        value="Male"
      />
      <v-radio
        label="Female"
        ripple
        value="Female"
      />
    </v-radio-group>
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  mixins: [formField],
  mounted: function () {
    this.field.value = 'Male'
  },
}
</script>
